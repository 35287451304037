<template>
    <v-select
        v-bind="$attrs"
        ref="select"
        color="#FF385C"
        class="sorting-select"
        :value="value"
        solo
        v-on="$listeners"
    >
        <template #append>
            <img src="../../assets/DownArrow2.svg" class="sorting-select__select-arrow" />
        </template>
    </v-select>
</template>

<script>

    export default {
        name: 'SortingSelect',
        inheritAttrs: false,
        props: {
            value: { type: Object, default: () => {} },
            type: { type: String, default: '' }
        }
    }
</script>

<style lang="scss">
    .sorting-select {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #707378;
        max-width: 200px;
        max-height: 20px;

        &__select-arrow {
            margin-left: 2px;
        }

        .v-select__slot {
            width: unset;
        }

        .v-input__control input {
            display: none;
        }

        .v-input__slot {
            border-radius: 10px;
            background: transparent !important;
        }

        .v-select__selection {
            padding: 0 0 0 8px;
            color: #707378;
            max-width: 100%;
        }

        .v-text-field.v-text-field--solo .v-input__control input {
            min-width: 0;
            max-width: 0;
            width: 0;
        }

        &.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
            box-shadow: none;
        }
        .v-input__slot {
            height: 20px;
        }
        &.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
            min-height: 20px;
        }
        &.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, 
        &.v-text-field.v-text-field--enclosed .v-text-field__details {
            padding: 0;
            height: 0;
        }
        &.theme--light.v-messages {
            height: 0;
            max-height: 0;
            min-height: 0;
        }
    }
    

    //     .v-input__slot {
    //         border-radius: 10px;
    //         background: #fff !important;
    //     }
    //     .v-text-field__details {
    //         display: none;
    //     }
    //     .v-input__icon--append > .v-icon {
    //         color: #ccc
    //     }
    //     &.v-text-field--outlined.v-input--is-focused fieldset,
    //     &.v-text-field--outlined.v-input--has-state fieldset {
    //         border: 1px solid #000;
    //     }
    //     &.v-text-field--outlined.error--text.v-input--is-focused fieldset,
    //     &.v-text-field--outlined.error--text.v-input--has-state fieldset {
    //         border: 1px solid #ff5252 ;
    //     }
    // }
    // .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    //     box-shadow: none;
    //     margin-bottom: 0;
    // }

    // .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details {
    //     min-height: 44px;
    // }

    // .v-select__selection{
    //     padding: 0 28px;
    //     color: #273155;
    // }

    // .prepend-icon {
    //     position: absolute;
    //     left: 16px;
    //     top: 12px;
    //     z-index: 1;
    // }
    // .v-input__prepend-outer {
    //     width: 0;
    //     margin: 14px 0 0 0 !important;
    // }

    // .v-input__control {
    //     margin-bottom: 12px;
    // }
</style>

<template>
    <v-combobox
        v-bind="$attrs"
        :value="value"
        multiple
        solo
        v-on="$listeners"
        class="base-combobox"
    >
        <template #append>
            <img src="../../assets/DownArrow.svg" class="base-select__append" />
        </template>
    </v-combobox>
</template>

<script>
    export default {
        name: 'BaseCombobox',
        props: {
            value: { type: Array, default: {} },
            type: { type: String, default: '' }
        },
    }
</script>

<style lang="scss">
    .base-combobox {
        &.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
        box-shadow: none;
        border: 1px solid #E5E6E9;
        border-radius: 12px;
        }
        &.mdi-checkbox-blank-outline {
            border-radius: 4px;
        }
    }
    
</style>